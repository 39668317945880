import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Disciplinary and Grievance Process">
    <Hero title="Disciplinary and Grievance Process" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Heading type="title2">Introduction</Heading>
            <Text>
              Where possible, we prefer to deal with HR issues informally, using
              a common sense approach and clear communication. In most cases,
              these discussions will take place between the Line Manager and the
              Employee. In the event that an issue needs to be escalated, the
              Head of Operations will be notified and the following disciplinary
              process may be invoked.
            </Text>
            <Heading type="title2">Disciplinary Procedure</Heading>
            <Heading type="title3">Purpose and Scope</Heading>
            <Text>
              Ember’s aim is to encourage improvement in individual conduct or
              performance. This procedure sets out the action which will be
              taken when disciplinary rules are breached.
            </Text>
            <Heading type="title3">Principles</Heading>
            <Text>
              The procedure is designed to establish the facts quickly and to
              deal consistently with disciplinary issues. While we may choose to
              suspend an employee during an investigation, no disciplinary
              action will be taken until the matter has been fully investigated.
            </Text>
            <Text>
              At every stage employees will be informed in writing of what is
              alleged and have the opportunity to state their case at a
              disciplinary meeting and be represented or accompanied, if they
              wish, by a trade union representative or a work colleague.
            </Text>
            <Heading type="title3">Procedure</Heading>
            <Text>
              Where the first offence is sufficiently serious, because, for
              example, it is deemed likely to have a serious harmful effect on
              the organisation, it may be justifiable to move directly to a
              final written warning or to dismissal.
            </Text>
            <Text>
              Such cases would be deemed Gross Misconduct. If, after
              investigation, it is confirmed that an employee has committed an
              offence of the following nature (the list is not exhaustive), the
              normal consequence will be dismissal without notice or payment in
              lieu of notice: dangerous driving, theft, damage to property,
              fraud, incapacity for work due to being under the influence of
              illegal drugs or alcohol, physical violence, bullying and gross
              insubordination.
            </Text>
            <Text>
              While the alleged gross misconduct is being investigated, the
              employee may be suspended, during which time he or she will be
              paid their normal pay rate. Any decision to dismiss will be taken
              by the employer only after full investigation.
            </Text>
            <Text>
              In all other cases, the following procedure will be followed:
            </Text>
            <Heading type="title3">Stage 1 - first written warning</Heading>
            <Text>
              If conduct, attendance or performance is unsatisfactory, the
              employee will have a discussion with their Line Manager and may be
              given a written warning. This may include an incident of dangerous
              driving, but could also include persistent underperformance in
              areas such as:
            </Text>
            <List>
              <ListItem>Poor cleaning of vehicles</ListItem>
              <ListItem>Poor driving (even if not dangerous)</ListItem>
              <ListItem>
                Low standards when interacting with the public or other road
                users
              </ListItem>
              <ListItem>Inappropriate behaviour</ListItem>
              <ListItem>
                Repeatedly causing damage to company property through
                carelessness
              </ListItem>
              <ListItem>
                Any combination of the above that merits discussion
              </ListItem>
            </List>
            <Text>CCTV will be reviewed and analysed where available.</Text>
            <Text>
              Such warnings will be permanently recorded, but in some cases the
              company may agree to disregard these after 12 months of
              satisfactory service. The employee will also be informed that a
              final written warning may be considered if there is no sustained
              satisfactory improvement or change.
            </Text>
            <Text>
              Where the first offence is sufficiently serious, because for
              example it causes danger to life, or is deemed likely to have a
              serious harmful effect on the organisation, it may be justifiable
              to move directly to a final written warning or to dismissal.
            </Text>
            <Heading type="title3">Stage 2 – final written warning</Heading>
            <Text>
              If the offence is deemed serious, or there is no improvement in
              standards, or if a further offence of a similar kind occurs, a
              final written warning will be given which will include the reason
              for the warning and a note that if no improvement results within 3
              months, action at Stage 3 will be taken.
            </Text>
            <Heading type="title3">Stage 3 – dismissal or demotion</Heading>
            <Text>
              If the conduct or performance has failed to improve, the employee
              may suffer demotion or dismissal.
            </Text>
            <Heading type="title3">Appeals</Heading>
            <Text>
              An employee who wishes to appeal against any disciplinary decision
              must do so to the Head of Operations within five working days of
              the decision being formally confirmed in writing. The Head of
              Operations will hear the appeal and decide the case impartially.
              This will be the final decision in the matter.
            </Text>
            <Heading type="title2">Grievance Procedure</Heading>
            <Heading type="title3">Dealing with grievances informally</Heading>
            <Text>
              If you have a grievance or complaint to do with your work or the
              people you work with you should, wherever possible, start by
              talking it over with your manager. You may be able to agree a
              solution informally between you.
            </Text>
            <Heading type="title3">Formal grievance</Heading>
            <Text>
              If the matter is serious and/or you wish to raise the matter
              formally you should set out the grievance in writing to your
              manager. You should stick to the facts and avoid language that is
              insulting or abusive.
            </Text>
            <Text>
              Where your grievance is against your manager and you feel unable
              to approach him or her you should talk to the Head of Operations
              (Andy) or one of the co-founders (Keith and Pierce).
            </Text>
            <Text>
              Your manager will call you to a Grievance Hearing, normally within
              five working days, to discuss your grievance. You have the right
              to be accompanied by a colleague or trade union representative at
              this meeting if you make a reasonable request.
            </Text>
            <Text>
              After the meeting the manager will give you a decision in writing,
              normally within 24 hours.
            </Text>
            <Text>
              If it is necessary to gather further information before making a
              decision your manger will inform you of this and the likely
              timescale involved.
            </Text>
            <Heading type="title3">Appeals</Heading>
            <Text>
              If you are unhappy with your manager’s decision and you wish to
              appeal you should let your manager know.
            </Text>
            <Text>
              You will be invited to an appeal meeting, normally within five
              working days, and your appeal will be heard by a more senior
              manager (or a company director). You have the right to be
              accompanied by a colleague or trade union representative at this
              meeting if you make a reasonable request.
            </Text>
            <Text>
              After the meeting the manager (or director) will give you a
              decision, normally within 24 hours. The manager’s (or director’s)
              decision is final.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
